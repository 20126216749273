import React from "react";
import * as Joi from "joi-browser";
import ApiResourceHelper from "../../../../helpers/ApiResourceHelper";
import Form from "../../../Form";
import axios from "axios";

class RequestForm extends Form {
  state = {
    data: {
      model: "",
      gender: "",
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      city: "",
      acceptedMarketingActivity: "",
    },
    errors: {},
    cities: [],
    selectedModel: {},
    isDisclaimerOpen: false,
    loading: false,
  };

  schema = {
    gender: Joi.string().required().label("Civilité"),
    lastName: Joi.string().required().min(2).max(30).label("Nom"),
    firstName: Joi.string().required().min(2).max(30).label("Prénom"),
    email: Joi.string()
      .required()
      .regex(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      .label("Email"),
    telephone: Joi.string()
      .required()
      .regex(/^(0)([0-9]){9}$/i)
      .label("Téléphone"),
    city: Joi.string().required().label("Ville"),
    model: Joi.string().allow("", null).label("Modèle"),
    acceptedMarketingActivity: Joi.boolean()
      .required()
      .label("J'accepte l'activité du marketing"),
  };

  componentDidMount() {
    this.getCities();
  }

  setDisclaimerOpen() {
    this.setState({ isDisclaimerOpen: !this.state.isDisclaimerOpen });
  }

  getUtm() {
    // get url
    const url_string = window.location.href; //
    const url = new URL(url_string);

    if (url.searchParams.get("utm_campaign")) {
      return {
        utmCampaign: url.searchParams.get("utm_campaign"),
        utmSource: url.searchParams.get("utm_source"),
        utmMedium: url.searchParams.get("utm_medium"),
      };
    }
    return {};
  }

  doSubmit = async () => {
    this.setState({ loading: true });

    const { data } = this.state;

    const { model: currentModel, form } = this.props;

    data.model = `/models/${currentModel.id}`;

    if (data.city === "") {
      delete data.city;
    }

    if (data.telephone === "") {
      delete data.telephone;
    }

    const { utmSource, utmCampaign, utmMedium } = this.getUtm();
    const finalData = { ...data, utmSource, utmMedium, utmCampaign };

    const apiHelper = new ApiResourceHelper(form.endpoint);

    try {
      const { data: request } = await apiHelper.create(finalData);
      const { onSwitch, setRequest } = this.props;
      setRequest(request);
      onSwitch("showroom");
    } catch (er) {}

    this.setState({
      loading: false,
    });
  };

  getCities = async () => {
    const cityHelper = new ApiResourceHelper("/cities?published=1");
    const { data: cities } = await cityHelper.findAll();
    this.setState({ cities });
  };

  getCity_list = () => {
    const { cities } = this.state;

    const city_list = [{ key: "", value: null }];

    cities.map((city) => {
      city_list.push({
        key: `${city.name}`,
        value: `/cities/${city.id}`,
      });

      return true;
    });

    return city_list;
  };
}

export default RequestForm;
