import React from 'react';
import styles from "../../../../pages/forms/brands/JeepForm/Jeep.module.css";
import { Collapse } from "react-bootstrap";

export default function Disclaimer({ open }) {
    // Extract the segment dynamically based on the position of "brands"
    const pathSegments = window.location.pathname.split('/');
    const brandIndex = pathSegments.indexOf("brands") + 1; // Find "brands" and get the next segment
    const segment = pathSegments[brandIndex] || "unknown";

    // Map each brand to its CNDP number
    const cndpNumbers1 = {
        fiat: "D-M-344/2017",
        fiatprofessional: "D-M-345/2017",
    };

    // Assign the appropriate CNDP number based on the segment
    const CNDP_NUMBER1 = cndpNumbers1[segment] || "Unknown"; // Default to "Unknown" if segment doesn't match

    console.log("Brand segment:", segment);
    console.log("CNDP Number:", CNDP_NUMBER1);

    return (
        <React.Fragment>
            <Collapse in={open} className={"mt-3 mb-3"}>
                <div id={styles["note-infos"]}>
                    <div className={"form-radios-group-infos-content"}>
                        <div className="privacyMask"></div>

                        <div className={"boxMessage"}>
                            <p className={"privacy-title pt-3 pb-0"}>
                                <u> Recueil du consentement et information</u>
                            </p>
                        </div>
                        <p>FIAT CHRYSLER AUTOMOBILE MOROCCO traite vos données personnelles.</p>
                        <p>
                            Conformément à la Loi 09-08, vous pouvez accéder aux informations vous concernant, les rectifier ou les supprimer, par courrier à MOTOR VILLAGE Ouled Benameur, RP 3011, KM 6, Bouskoura (seules les demandes signées, accompagnées de la photocopie d’une pièce d’identité seront traitées).
                        </p>
                        <p>
                            Ce traitement de données a été déclaré auprès de la CNDP sous le numéro {CNDP_NUMBER1}.
                        </p>
                    </div>
                </div>
            </Collapse>
        </React.Fragment>
    );
}

